/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import AudioControls from './AudioControls';
import VolumeControl from './VolumeControl';
import ShareModal from './shareModal';
import QueuePopup from './QueuePopup';
import { usePlay } from '../context/Play';
import { trigger } from '../utils/events';
import { useApi } from '../context/ApiProvider';
import { timeFormat } from '../utils/timeFormat';
import {
  Box,
  Slider,
  Stack,
  Typography,
  Link,
  Avatar,
  Tooltip,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { RestartAlt, PlayArrow } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const FixedContainer = styled('div')`
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 2;
`;

const AudioPlayerStyled = styled('div')(({ theme }) => ({
  backgroundColor: '#333337',
  height: '70px',
  padding: '10px 30px',

  [theme.breakpoints.down('sm')]: {
    height: '145px',
    padding: '10px',
  },

  '.content': {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const TrackInfo = styled('div')`
  position: relative;
  width: 33%;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Controls = styled('div')`
  width: 33%;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AudioPlayer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const intervalRef = useRef();
  const trackPlaysIntervalRef = useRef();

  const [setLikes, setSetLikes] = useState(0);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [playbackModalOpen, setPlaybackModalOpen] = useState(false);
  const [usePlaybackTime, setUsePlaybackTime] = useState(false);
  const [playbackTime, setPlaybackTime] = useState(false);

  const {
    isPlaying,
    setIsPlaying,
    playingSet,
    audioRef,
    setPlayingProgress,
    playPause,
    onSetEnded,
    trackVolume,
    setTrackVolume,
  } = usePlay();

  const {
    id,
    set_name,
    artist_meta,
    art_url,
    likes,
    set_sponsors,
    set_tracks,
    user_fav,
  } = playingSet;

  const sponsor = set_sponsors ? set_sponsors[0] : null;

  function startTimer() {
    clearInterval(intervalRef.current);
    clearInterval(trackPlaysIntervalRef.current);

    if (audioRef && !audioRef.paused) {
      trackPlays(audioRef.currentTime);
    }

    intervalRef.current = setInterval(() => {
      if (audioRef?.ended) {
        setIsPlaying(false);
        setTrackProgress(0);
        setPlayingProgress(0);
        clearInterval(intervalRef.current);
        clearInterval(trackPlaysIntervalRef.current);
        onSetEnded(id);
      } else if (!audioRef || audioRef.paused) {
        return;
      } else {
        setTrackProgress(audioRef.currentTime);
        setPlayingProgress(audioRef.currentTime);
      }
    }, [1000]);

    // call trackPlays every 30 seconds
    trackPlaysIntervalRef.current = setInterval(() => {
      if (!audioRef || audioRef.paused) return;

      if (audioRef.ended) {
        clearInterval(trackPlaysIntervalRef.current);
      } else {
        trackPlays(audioRef.currentTime);
      }
    }, [30000]);
  }

  useEffect(() => {
    if (set_tracks) {
      const currentTrackPos = getPlayingTrackPosition();
      setCurrentTrack(set_tracks[currentTrackPos]);

      // Logic to set pageTitle

      if (!currentTrack) {
        return;
      }

      const artistNames = currentTrack?.track_meta?.artists
        .filter((artist) => {
          return (
            artist?.role?.toLowerCase() === 'id' ||
            artist?.role?.toLowerCase() === 'producer'
          );
        })
        .map((artist) => artist?.artist_meta?.name)
        .join(' & ');
      let currentTitle = `${artistNames} - ${currentTrack?.track_meta?.title}`;

      if (
        currentTrack?.track_meta?.mix_type &&
        currentTrack?.track_meta?.mix_meta?.name
      ) {
        if (currentTrack.track_meta.mix_type === 'remix') {
          currentTitle += ` (${currentTrack.track_meta.mix_meta.name} Remix)`;
        } else if (currentTrack.track_meta.mix_type === 'edit') {
          currentTitle += ` (${currentTrack.track_meta.mix_meta.name} Edit)`;
        }
      }
      setCurrentTitle(currentTitle);
    }
  }, [trackProgress, currentTrack]);

  useEffect(() => {
    setMediaSessionInfo(currentTitle);
  }, [currentTitle]);

  useEffect(() => {
    setPlaybackModalOpen(false);
    setUsePlaybackTime(false);
    setPlaybackTime(0);
    if (audioRef && audioRef.currentTime > 0) {
      audioRef.currentTime = 0;
    }
  }, [audioRef]);

  useEffect(() => {
    if (!playbackModalOpen) {
      const startSetAt = usePlaybackTime ? playbackTime : 0;
      setTrackProgress(startSetAt);
      setPlayingProgress(startSetAt);
      if (startSetAt && usePlaybackTime) {
        audioRef.currentTime = startSetAt;
      }
      startTimer();
      setSetLikes(likes);
    }

    if (!usePlaybackTime) {
      handlePlay();
    }
  }, [audioRef, usePlaybackTime]);

  useEffect(() => {
    if (audioRef) {
      audioRef.volume = trackVolume;
    }
  }, [trackVolume]);

  function setMediaSessionInfo(trackTitle) {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: trackTitle,
        artist: `${artist_meta?.name} - ${set_name} [${sponsor?.sponsor}]`,
        artwork: [
          { src: art_url, sizes: '96x96', type: 'image/png' },
          { src: art_url, sizes: '128x128', type: 'image/png' },
          { src: art_url, sizes: '192x192', type: 'image/png' },
          { src: art_url, sizes: '256x256', type: 'image/png' },
          { src: art_url, sizes: '384x384', type: 'image/png' },
          { src: art_url, sizes: '512x512', type: 'image/png' },
        ],
      });

      navigator.mediaSession.setActionHandler('play', () => {
        playPause();
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        playPause();
      });
    }
  }

  function getPlayingTrackPosition() {
    return set_tracks.findIndex(
      (track) =>
        trackProgress >= track.start_time && trackProgress < track.end_time
    );
  }

  function toPrevTrack() {
    const playingTrack = getPlayingTrackPosition();
    const prevTrack = set_tracks[playingTrack - 1];
    if (!prevTrack) return;
    const startTime = Number(prevTrack.start_time);
    onScrub(startTime);
    onScrubEnd();
  }

  function toNextTrack() {
    const playingTrack = getPlayingTrackPosition();
    const nextTrack = set_tracks[playingTrack + 1];
    if (!nextTrack) return;
    const startTime = Number(nextTrack.start_time);
    onScrub(startTime);
    onScrubEnd();
  }

  function onScrub(value) {
    clearInterval(intervalRef.current);
    audioRef.currentTime = value;
    setTrackProgress(value);
    setPlayingProgress(audioRef.currentTime);
  }

  function onScrubEnd() {
    if (!isPlaying) {
      setIsPlaying(true);
      audioRef.play();
    }
    startTimer();
  }

  async function handleLike() {
    if (trackProgress && id) {
      setLoading(true);
      const data = {
        set_meta_id: id,
        set_timestamp: trackProgress,
      };
      const response = await api.post(`/set-likes`, data);
      if (response.ok) {
        const body = response.body;
        setSetLikes(body.likes);
        showLikeNotification();
        callFavorites();
      } else {
        console.log(response);
      }
      setLoading(false);
    }
  }

  async function callFavorites() {
    api.put(`/favs/set/${id}/likes`);
    const trackId = currentTrack?.track_meta_id;
    await api.put(`/favs/track/${trackId}/likes`);

    trigger('updateMetaLikes');
  }

  function showLikeNotification() {
    const playingTrackPosition = getPlayingTrackPosition();
    const playingTrack = set_tracks[playingTrackPosition];
    enqueueSnackbar(
      `${playingTrack.track_meta.title} has been saved to your likes!`
    );
  }

  function trackPlays(trackProgress) {
    if (trackProgress && id) {
      const data = {
        set_meta_id: id,
        current_timestamp: trackProgress,
      };
      api.put(`/plays`, data);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function handlePlay() {
    if (!audioRef || !id || !isPlaying) return;

    if (user_fav.current_timestamp > 0) {
      setPlaybackModalOpen(true);
      setPlaybackTime(user_fav.current_timestamp);
      return;
    }
  }

  function resumePlaybackTime() {
    setPlaybackModalOpen(false);
    setUsePlaybackTime(true);
    onScrubEnd();
  }

  let timeDuration = 0;
  let timeProgress = 0;
  let duration = 0;
  if (audioRef) {
    duration = audioRef.duration;
    timeDuration = timeFormat(duration);
    timeProgress = timeFormat(trackProgress);
  }

  return (
    <>
      <FixedContainer>
        <Slider
          aria-label="Volume"
          value={trackProgress ? trackProgress : 0}
          onChange={(e) => onScrub(e.target.value)}
          onChangeCommitted={() => onScrubEnd()}
          step={1}
          min={0}
          max={duration ? duration : 0}
          sx={{
            padding: '0 !important',
            mb: '-5px',
          }}
        />

        <AudioPlayerStyled>
          <div className="content">
            {playingSet && (
              <TrackInfo>
                <Avatar
                  src={art_url}
                  alt={`track artwork for ${set_name} by ${artist_meta?.name}`}
                />
                <Stack
                  direction="column"
                  marginLeft={1}
                  width={{ xs: '75%', md: '100%' }}
                >
                  <Link
                    href=""
                    to={`/tracks/${currentTrack?.track_meta_id}`}
                    underline="hover"
                  >
                    {currentTrack && currentTitle && (
                      <Tooltip title={currentTrack?.track_meta?.title}>
                        <Typography
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {currentTitle}
                        </Typography>
                      </Tooltip>
                    )}
                  </Link>
                  <Link
                    href=""
                    to={`/sets/${playingSet?.id}`}
                    underline="hover"
                  >
                    <Typography
                      variant="body2"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {playingSet?.artist_meta?.name} - {playingSet?.set_name}
                    </Typography>
                  </Link>
                </Stack>
                <Box
                  display={{ xs: 'flex', sm: 'none', md: 'none' }}
                  sx={{ width: '35%', justifyContent: 'flex-end' }}
                >
                  <QueuePopup />
                </Box>
              </TrackInfo>
            )}
            <Controls>
              <AudioControls
                isPlaying={isPlaying}
                onPrevClick={toPrevTrack}
                onNextClick={toNextTrack}
                onPlayPauseClick={playPause}
                onLikeClick={handleLike}
                onShareClick={() => setShareModalOpen(true)}
                loading={loading}
                likes={setLikes}
              />
            </Controls>
            <Stack
              spacing={{ xs: 0, md: 1 }}
              direction={{ xs: 'column', sm: 'row' }}
              width={{ xs: '100%', md: '33%' }}
              justifyContent={{ xs: 'center', sm: 'end' }}
              alignItems={{ xs: 'center', md: 'center' }}
            >
              <Box display={{ md: 'block' }}>
                {Boolean(timeDuration && timeProgress) && (
                  <Typography variant="body2" color={'white'}>
                    {timeProgress} / {timeDuration}
                  </Typography>
                )}
              </Box>
              <Box width={150} display={{ xs: 'none', lg: 'block' }}>
                <VolumeControl
                  setTrackVolume={setTrackVolume}
                  trackVolume={trackVolume}
                />
              </Box>
              <Box display={{ xs: 'none', sm: 'block', md: 'block' }}>
                <QueuePopup />
              </Box>
            </Stack>
          </div>
        </AudioPlayerStyled>
      </FixedContainer>

      <ShareModal
        open={shareModalOpen}
        onCloseModal={() => setShareModalOpen(false)}
        setId={id}
        setMessage={`Check out this set by ${artist_meta?.name} on KIP: `}
        trackId={currentTrack?.track_meta_id}
        trackMessage={`Check out ${currentTrack?.track_meta?.title} by ${currentTrack?.track_meta?.artist} on KIP: `}
      />

      <Dialog
        open={playbackModalOpen}
        onClose={() => setPlaybackModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Continue Playback?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Last time you listened to this set you left it at{' '}
            {timeFormat(playbackTime)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPlaybackModalOpen(false)}
            startIcon={<RestartAlt />}
            variant="outlined"
          >
            Restart
          </Button>
          <Button
            onClick={resumePlaybackTime}
            autoFocus
            startIcon={<PlayArrow />}
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AudioPlayer;
