/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AudioControls from './AudioControls';
import VolumeControl from './VolumeControl';
import QueuePopup from './QueuePopup';
import {
  Box,
  Slider,
  Stack,
  Typography,
  Avatar,
  styled,
  Link,
} from '@mui/material';

const FixedContainer = styled('div')`
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 2;
`;

const AudioPlayerStyled = styled('div')(({ theme }) => ({
  backgroundColor: '#333337',
  height: '70px',
  padding: '10px 30px',

  [theme.breakpoints.down('sm')]: {
    height: '145px',
    padding: '10px',
  },

  '.content': {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const TrackInfo = styled('div')`
  position: relative;
  width: 33%;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Controls = styled('div')`
  width: 33%;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AudioPlayerLoading = () => {
  function placeholder() {}

  return (
    <>
      <FixedContainer>
        <Slider
          aria-label="Volume"
          value={0}
          onChange={placeholder}
          onChangeCommitted={placeholder}
          step={1}
          min={0}
          max={0}
          sx={{
            padding: '0 !important',
            mb: '-5px',
          }}
        />

        <AudioPlayerStyled>
          <div className="content">
            <TrackInfo>
              <Avatar src={''} />
              <Stack
                direction="column"
                marginLeft={1}
                width={{ xs: '75%', md: '100%' }}
              >
                <Link href="" underline="hover">
                  <Typography
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    color={'suc'}
                  >
                    Loading...
                  </Typography>
                </Link>
                <Link href="" underline="hover">
                  <Typography
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    color={'suc'}
                  >
                    Loading...
                  </Typography>
                </Link>
              </Stack>
              <Box
                display={{ xs: 'flex', sm: 'none', md: 'none' }}
                sx={{ width: '35%', justifyContent: 'flex-end' }}
              >
                <QueuePopup />
              </Box>
            </TrackInfo>
            <Controls>
              <AudioControls
                isPlaying={placeholder}
                onPrevClick={placeholder}
                onNextClick={placeholder}
                onPlayPauseClick={placeholder}
                onLikeClick={placeholder}
                onShareClick={placeholder}
                loading={false}
                likes={0}
              />
            </Controls>
            <Stack
              spacing={{ xs: 0, md: 1 }}
              direction={{ xs: 'column', sm: 'row' }}
              width={{ xs: '100%', md: '33%' }}
              justifyContent={{ xs: 'center', sm: 'end' }}
              alignItems={{ xs: 'center', md: 'center' }}
            >
              <Box width={150} display={{ xs: 'none', lg: 'block' }}>
                <VolumeControl setTrackVolume={placeholder} trackVolume={1} />
              </Box>
              <Box display={{ xs: 'none', sm: 'block', md: 'block' }}>
                <QueuePopup />
              </Box>
            </Stack>
          </div>
        </AudioPlayerStyled>
      </FixedContainer>
    </>
  );
};

export default AudioPlayerLoading;
