import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultArt from '../assets/images/dj_kip_art.png';
import { getImg } from '../utils/getImg';
import { useNavigate } from 'react-router-dom';
import {
  useMediaQuery,
  useTheme,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Badge,
  Link,
  Box,
  Grid,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import { useApi } from '../context/ApiProvider';
import {
  ArrowUpward,
  ArrowDownward,
  Favorite,
  FavoriteBorder,
} from '@mui/icons-material';
import MobileCard from './MobileCard';
import { useSnackbar } from 'notistack';
import TrackActionButtons from '../components/TrackActionButtons';
import ArtistsName from './ArtistsName';

const TrackItem = ({ item, asCard, onTableRow, showGenreLink = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const api = useApi();
  const {
    art_url_sm,
    artist,
    artists,
    release_date,
    title,
    id,
    artist_meta,
    label_name,
    label_meta,
    genre,
    mix_type,
    purchase_url,
    user_fav,
  } = item;
  const [isFav, setIsFav] = useState(user_fav?.is_fav);
  const [showLikes, setShowLikes] = useState(user_fav?.show_likes);
  const trackImg = getImg(art_url_sm) || defaultArt;
  const releaseDateFormatted = release_date
    ? new Date(release_date).toLocaleDateString('en-US')
    : '';
  const likedDateFormatted = user_fav?.first_liked_date
    ? new Date(user_fav?.first_liked_date).toLocaleDateString('en-US')
    : '';
  const mix_artists = artists.filter((artist) => artist.role === 'mixer');
  const mix_artistsNames = mix_artists
    .map((artist) => artist.artist_meta.name)
    .join(' & ');

  const trackTitle = `${title} ${
    mix_artistsNames ? `(${mix_artistsNames} ${mix_type})` : ''
  }`;

  function goToTrack() {
    navigate(`/tracks/${id}`);
  }

  async function handleFavoriteClick() {
    const response = await api.put(`/favs/track/${item.id}`);
    if (response.ok) {
      setIsFav(response.body.is_fav);
      handleLikeNotification(response.body.is_fav, 'favorites');
    }
  }

  async function handleLikeClick() {
    const response = await api.put(`/favs/track/${item.id}/likes-toggle`);
    if (response.ok) {
      setShowLikes(response.body.show_likes);
      handleLikeNotification(response.body.show_likes, 'likes');
    }
  }

  function handleLikeNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`Track added to ${type}!`);
    } else {
      enqueueSnackbar(`Track removed from ${type}!`);
    }
  }

  function handleGenreClick(genre) {
    const encodedGenre = encodeURIComponent(genre.trim());
    navigate(`/tracks?genre=${encodedGenre}`);
  }

  return (
    <>
      {asCard ? (
        <MobileCard
          handleFavoriteClick={handleFavoriteClick}
          handleLikeClick={handleLikeClick}
          isFav={isFav}
          showLikes={showLikes}
          likes={user_fav?.user_likes}
          date={releaseDateFormatted}
          goToItem={goToTrack}
          img={trackImg}
          title={artist}
          subtitle={trackTitle}
          subtitleExtra={label_name}
          chips={[genre]}
          onChipClick={handleGenreClick}
        >
          <TrackActionButtons
            id={id}
            artistName={artist_meta?.name}
            trackName={title}
            purchase_url={purchase_url}
            is_purchased={user_fav?.is_purchased ?? false}
            mix_type={mix_type}
          />
        </MobileCard>
      ) : (
        <TableRow
          sx={{
            '.MuiTableCell-root': { padding: onTableRow ? '5px 16px' : 1 },
            ':hover': {
              backgroundColor: 'info.main',
              '& .MuiAvatar-root': {
                borderStyle: 'solid',
                borderColor: 'primary.main',
              },
            },
          }}
        >
          <TableCell component="th" scope="row">
            <Avatar
              src={trackImg || defaultArt}
              sx={{ width: 56, height: 56 }}
            />
          </TableCell>
          <TableCell align="left" sx={{ maxWidth: 200 }}>
            <ArtistsName
              artists={artists || []}
              underline="hover"
              linkColor="primary"
            />
          </TableCell>
          <TableCell
            align="left"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Link
              href={''}
              to={`/tracks/${id}`}
              underline="hover"
              fontWeight="bold"
            >
              {title}
            </Link>{' '}
            {mix_artists.length > 0 && (
              <>
                {'('}
                {mix_artists.map((mix_artist, index) => (
                  <React.Fragment key={mix_artist.id}>
                    <Link
                      href={''}
                      to={`/artists/${mix_artist.artist_meta.id}`}
                      underline="hover"
                      fontWeight="bold"
                    >
                      {mix_artist.artist_meta.name}
                    </Link>
                    {index < mix_artists.length - 1 && <spam> & </spam>}
                  </React.Fragment>
                ))}{' '}
                {mix_type}
                {')'}
              </>
            )}
          </TableCell>
          <TableCell align="left">
            <Link href={''} to={`/labels/${label_meta?.id}`} underline="hover">
              {label_name}
            </Link>
          </TableCell>
          <TableCell align="left">
            {showGenreLink ? (
              <Typography
                color={'primary'}
                sx={{ cursor: 'pointer', fontSize: '1rem' }}
                onClick={() => handleGenreClick(genre)}
              >
                {genre}
              </Typography>
            ) : (
              <>{genre}</>
            )}
          </TableCell>
          <TableCell align="left">{releaseDateFormatted}</TableCell>
          <TableCell align="right">
            <Stack direction="row" alignItems="center">
              <IconButton aria-label="favorite" onClick={handleLikeClick}>
                <Badge
                  color="primary"
                  badgeContent={user_fav?.user_likes}
                  max={999}
                >
                  {showLikes ? <Favorite /> : <FavoriteBorder />}
                </Badge>
              </IconButton>
            </Stack>
          </TableCell>
          <TableCell align="left">{likedDateFormatted}</TableCell>
        </TableRow>
      )}
    </>
  );
};

TrackItem.propTypes = {
  item: PropTypes.object,
  asCard: PropTypes.bool,
  onTableRow: PropTypes.bool,
  showGenreLink: PropTypes.bool,
};

const TracksTable = ({
  items,
  onTableInteraction,
  tableInteractionConfig,
  hideTableInteraction,
  onTableRow,
  showGenreLink = true,
}) => {
  const [directionAsc, setDirectionAsc] = useState(false);
  const [activeCol, setActiveCol] = useState(
    hideTableInteraction ? '' : 'release_date'
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTableHeadClick = (selectedColumn) => {
    if (hideTableInteraction) return;

    let dir = directionAsc;
    if (selectedColumn === activeCol) {
      setDirectionAsc(!directionAsc);
      dir = !dir;
    } else {
      setDirectionAsc(true);
      dir = true;
    }
    setActiveCol(selectedColumn);
    let orderBy =
      selectedColumn === 'likes'
        ? 'user_track_favs.total_user_likes'
        : `track_meta.${selectedColumn}`;
    orderBy =
      selectedColumn === 'liked_on'
        ? 'user_track_favs.first_liked_date'
        : orderBy;
    onTableInteraction({
      ...tableInteractionConfig,
      order_by: orderBy,
      order_direction: dir ? 'asc' : 'desc',
    });
  };

  if (!items.length) return <></>;

  return (
    <>
      {isMobile ? (
        <Box sx={{ flexGrow: 1, textAlign: 'left' }} my={3}>
          <Grid container spacing={2}>
            {items.map((item) => (
              <Grid item xs={12} lg={3} key={item.id}>
                <TrackItem item={item} asCard />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ overflow: 'visible' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="tracks table">
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': { padding: '16px 8px' },
                }}
              >
                <TableCell
                  sx={{
                    width: '4%',
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'artist' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '15%',
                  }}
                  onClick={() => handleTableHeadClick('artist')}
                >
                  Artist
                  {activeCol === 'artist' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'title' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '25%',
                  }}
                  onClick={() => handleTableHeadClick('title')}
                >
                  Title
                  {activeCol === 'title' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'label_name' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '15%',
                  }}
                  onClick={() => handleTableHeadClick('label_name')}
                >
                  Label
                  {activeCol === 'label_name' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'genre' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '15%',
                  }}
                  onClick={() => handleTableHeadClick('genre')}
                >
                  Genre
                  {activeCol === 'genre' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight:
                      activeCol === 'release_date' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '10%',
                  }}
                  onClick={() => handleTableHeadClick('release_date')}
                >
                  Released
                  {activeCol === 'release_date' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    width: '8%',
                    fontWeight: activeCol === 'likes' ? 'bold' : 'normal',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleTableHeadClick('likes')}
                >
                  Likes
                  {activeCol === 'likes' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'liked_on' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '8%',
                  }}
                  onClick={() => handleTableHeadClick('liked_on')}
                >
                  Liked on
                  {activeCol === 'liked_on' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TrackItem
                  item={item}
                  key={item.id}
                  onTableRow={onTableRow}
                  showGenreLink={showGenreLink}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

TracksTable.propTypes = {
  items: PropTypes.array,
  onTableInteraction: PropTypes.func,
  tableInteractionConfig: PropTypes.object,
  hideTableInteraction: PropTypes.bool,
  onTableRow: PropTypes.bool,
  showGenreLink: PropTypes.bool,
};

export default TracksTable;
