import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import defaultArt from '../assets/images/dj_kip_art.png';
import { usePlay } from '../context/Play';
import { useApi } from '../context/ApiProvider';
import { getImg } from '../utils/getImg';
import {
  useMediaQuery,
  useTheme,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Link,
  Button,
  Box,
  Grid,
  IconButton,
  Stack,
  ButtonGroup,
  Badge,
} from '@mui/material';
import {
  ArrowUpward,
  ArrowDownward,
  PlayArrow,
  Pause,
  Favorite,
  FavoriteBorder,
} from '@mui/icons-material';
import ShareButton from './action_buttons/ShareButton';
import QueueButton from './action_buttons/QueueButton';
import MobileCard from './MobileCard';
import ArtistsName from './ArtistsName';
import { useSnackbar } from 'notistack';

const SetItem = ({ item, asCard, showGenreLink = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    art_url_xl,
    artist,
    release_date,
    set_name,
    id,
    artists,
    set_sponsors,
    genre,
    track_genres,
    user_fav,
  } = item;
  const [isSetFocus, setIsSetFocus] = useState(false);
  const [isFav, setIsFav] = useState(user_fav?.is_fav);
  const [showLikes, setShowLikes] = useState(user_fav?.show_likes);
  const api = useApi();

  const setImg = getImg(art_url_xl) || defaultArt;
  const { isPlaying, playingMetaId, audioRef, createAudioRef, playPause } =
    usePlay();
  const isSetPlaying = playingMetaId === item.id && isPlaying;
  const releaseDateFormatted = release_date
    ? new Date(release_date).toLocaleDateString('en-US')
    : '';
  const likedDateFormatted = user_fav?.first_liked_date
    ? new Date(user_fav?.first_liked_date).toLocaleDateString('en-US')
    : '';
  const sponsor = set_sponsors ? set_sponsors[0] : {};
  const sponsorName = sponsor?.sponsor;
  const sponsorIsArtist = sponsor?.sponsor_type === 'artist_meta';
  let navigate = useNavigate();

  function goToSet() {
    navigate(`/sets/${item.id}`);
  }

  const handlePlayPauseClick = async () => {
    const audio =
      isSetPlaying || playingMetaId === item.id
        ? audioRef
        : await createAudioRef(item.id);
    if (audio) {
      playPause(audio);
    }
  };

  async function handleFavoriteClick() {
    const response = await api.put(`/favs/set/${item.id}`);
    if (response.ok) {
      setIsFav(response.body.is_fav);
      handleLikeNotification(response.body.is_fav, 'favorites');
    }
  }

  async function handleLikeClick() {
    const response = await api.put(`/favs/set/${item.id}/likes-toggle`);
    if (response.ok) {
      setShowLikes(response.body.show_likes);
      handleLikeNotification(response.body.show_likes, 'likes');
    }
  }

  function handleLikeNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`Set added to ${type}!`);
    } else {
      enqueueSnackbar(`Set removed from ${type}!`);
    }
  }

  function handleGenreClick(genre) {
    const encodedGenre = encodeURIComponent(genre.trim());
    navigate(`/sets?genre=${encodedGenre}`);
  }

  return (
    <>
      {asCard ? (
        <MobileCard
          handleFavoriteClick={handleFavoriteClick}
          handleLikeClick={handleLikeClick}
          isFav={isFav}
          likes={user_fav?.user_likes}
          showLikes={showLikes}
          date={releaseDateFormatted}
          goToItem={goToSet}
          img={setImg}
          title={artist}
          subtitle={set_name}
          subtitleExtra={sponsorName}
          chips={track_genres}
          onChipClick={handleGenreClick}
        >
          <ButtonGroup size="large" variant="contained">
            <Button
              variant="contained"
              color="primary"
              onClick={handlePlayPauseClick}
              startIcon={isSetPlaying ? <Pause /> : <PlayArrow />}
            >
              {isSetPlaying ? 'Pause' : 'Play'}
            </Button>
            <QueueButton setId={item.id} />
            <ShareButton id={item.id} artistName={artist} />
          </ButtonGroup>
        </MobileCard>
      ) : (
        <TableRow
          className={isSetPlaying ? 'playing' : ''}
          sx={{
            '.MuiTableCell-root': { padding: 1 },
            ':hover': {
              backgroundColor: 'info.main',
              '& .MuiAvatar-root': {
                borderStyle: 'solid',
                borderColor: 'primary.main',
              },
            },
          }}
          onMouseEnter={() => setIsSetFocus(true)}
          onMouseLeave={() => setIsSetFocus(false)}
        >
          <TableCell component="th" scope="row" sx={{ position: 'relative' }}>
            <Avatar src={setImg} sx={{ width: 56, height: 56 }} />
            {isSetFocus && (
              <IconButton
                sx={{ position: 'absolute', top: '12px', left: '10px' }}
                color="primary"
                onClick={handlePlayPauseClick}
                size="large"
              >
                {isSetPlaying ? <Pause /> : <PlayArrow />}
              </IconButton>
            )}
          </TableCell>
          <TableCell align="left" sx={{ maxWidth: 200 }}>
            <ArtistsName
              artists={artists || []}
              underline="hover"
              linkColor="primary"
            />
          </TableCell>
          <TableCell align="left" sx={{ maxWidth: 200 }}>
            <Link
              href={''}
              to={`/sets/${id}`}
              underline="hover"
              fontWeight="bold"
            >
              {set_name}
            </Link>
          </TableCell>

          <TableCell align="left">
            {sponsorIsArtist ? (
              <Link
                href={''}
                to={`/artists/${sponsor?.sponsor_id}`}
                underline="hover"
              >
                {sponsorName}
              </Link>
            ) : (
              <Link
                href={''}
                to={`/labels/${sponsor?.sponsor_id}`}
                underline="hover"
              >
                {sponsorName}
              </Link>
            )}
          </TableCell>
          <TableCell align="left">
            {showGenreLink ? (
              <Typography
                color={'primary'}
                sx={{ cursor: 'pointer', fontSize: '1rem' }}
                onClick={() => handleGenreClick(genre)}
              >
                {genre}
              </Typography>
            ) : (
              <>{genre}</>
            )}
          </TableCell>
          <TableCell align="left">{releaseDateFormatted}</TableCell>
          <TableCell align="right">
            <Stack direction="row" alignItems="center">
              <IconButton aria-label="favorite" onClick={handleLikeClick}>
                <Badge
                  color="primary"
                  badgeContent={user_fav?.user_likes}
                  max={999}
                >
                  {showLikes ? <Favorite /> : <FavoriteBorder />}
                </Badge>
              </IconButton>
            </Stack>
          </TableCell>
          <TableCell align="left">{likedDateFormatted}</TableCell>
        </TableRow>
      )}
    </>
  );
};

SetItem.propTypes = {
  item: PropTypes.object,
  asCard: PropTypes.bool,
  showGenreLink: PropTypes.bool,
};

const SetsTable = ({
  items,
  onTableInteraction,
  tableInteractionConfig,
  hideTableInteraction,
  showGenreLink = true,
}) => {
  const [directionAsc, setDirectionAsc] = useState(false);
  const [activeCol, setActiveCol] = useState(
    hideTableInteraction ? '' : 'release_date'
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTableHeadClick = (selectedColumn) => {
    if (hideTableInteraction) return;
    let dir = directionAsc;
    if (selectedColumn === activeCol) {
      setDirectionAsc(!directionAsc);
      dir = !dir;
    } else {
      setDirectionAsc(true);
      dir = true;
    }
    setActiveCol(selectedColumn);

    let orderBy =
      selectedColumn === 'sponsor'
        ? 'set_sponsors.sponsor'
        : `set_meta.${selectedColumn}`;
    orderBy =
      selectedColumn === 'likes' ? 'user_set_favs.total_user_likes' : orderBy;
    orderBy =
      selectedColumn === 'liked_on'
        ? 'user_set_favs.first_liked_date'
        : orderBy;
    onTableInteraction({
      ...tableInteractionConfig,
      order_by: orderBy,
      order_direction: dir ? 'asc' : 'desc',
    });
  };

  if (!items.length) return <></>;

  return (
    <>
      {isMobile ? (
        <Box sx={{ flexGrow: 1, textAlign: 'left' }} my={3}>
          <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid item xs={12} lg={3} key={`${index}-${item.id}`}>
                <SetItem item={item} asCard />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ overflow: 'visible' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': { padding: '16px 8px' },
                }}
              >
                <TableCell
                  sx={{
                    width: '4%',
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'artist' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '15%',
                  }}
                  onClick={() => handleTableHeadClick('artist')}
                >
                  Artist
                  {activeCol === 'artist' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'set_name' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    color: '#fff',
                    width: '25%',
                  }}
                  onClick={() => handleTableHeadClick('set_name')}
                >
                  Set
                  {activeCol === 'set_name' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'sponsor' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '15%',
                  }}
                  onClick={() => handleTableHeadClick('sponsor')}
                >
                  Curator
                  {activeCol === 'sponsor' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'genre' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '15%',
                  }}
                  onClick={() => handleTableHeadClick('genre')}
                >
                  Genre
                  {activeCol === 'genre' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight:
                      activeCol === 'release_date' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '10%',
                  }}
                  onClick={() => handleTableHeadClick('release_date')}
                >
                  Recorded
                  {activeCol === 'release_date' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    width: '8%',
                    fontWeight: activeCol === 'likes' ? 'bold' : 'normal',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleTableHeadClick('likes')}
                >
                  Likes
                  {activeCol === 'likes' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: activeCol === 'liked_on' ? 'bold' : 'normal',
                    cursor: 'pointer',
                    width: '8%',
                  }}
                  onClick={() => handleTableHeadClick('liked_on')}
                >
                  Liked on
                  {activeCol === 'liked_on' && (
                    <>
                      {directionAsc ? (
                        <ArrowUpward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="small"
                          sx={{ verticalAlign: 'top' }}
                        />
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <SetItem
                  item={item}
                  key={`${index}-${item.id}`}
                  showGenreLink={showGenreLink}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

SetsTable.propTypes = {
  items: PropTypes.array,
  onTableInteraction: PropTypes.func,
  tableInteractionConfig: PropTypes.object,
  hideTableInteraction: PropTypes.bool,
  showGenreLink: PropTypes.bool,
};

export default SetsTable;
