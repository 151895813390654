import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultArt from '../assets/images/dj_kip_art.png';
import { useNavigate } from 'react-router-dom';
import {
  useMediaQuery,
  useTheme,
  Card,
  CardHeader,
  CardActionArea,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Badge,
  Link,
  Box,
  Grid,
  Stack,
  IconButton,
} from '@mui/material';
import { useApi } from '../context/ApiProvider';
import { useSnackbar } from 'notistack';
import {
  Visibility,
  VisibilityOutlined,
  Star,
  StarBorder,
} from '@mui/icons-material';
import ArtistsName from './ArtistsName';

const TrackItem = ({ item, asCard }) => {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const api = useApi();
  const { art_url, artist, artists, title, id, user_fav, set_meta } = item;
  const [isFav, setIsFav] = useState(user_fav?.is_fav);
  const [showLikes, setShowLikes] = useState(user_fav?.show_likes);
  const dateFormatted = set_meta?.release_date
    ? new Date(set_meta.release_date).toLocaleDateString('en-US')
    : '';
  const trackTitle = `${title} in ${set_meta?.set_name}`;

  function goToTrack() {
    navigate(`/tracks/${id}`);
  }

  async function handleFavoriteClick() {
    const response = await api.put(`/favs/track/${item.id}`);
    if (response.ok) {
      setIsFav(response.body.is_fav);
      handleFavNotification(response.body.is_fav, 'favorites');
    }
  }

  function handleFavNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`ID added to ${type}!`);
    } else {
      enqueueSnackbar(`ID removed from ${type}.`);
    }
  }

  async function handleLikeClick() {
    const response = await api.put(`/favs/track/${item.id}/likes-toggle`);
    if (response.ok) {
      setShowLikes(response.body.show_likes);
      handleLikeNotification(response.body.show_likes, 'watching');
    }
  }

  function handleLikeNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`Started ${type} ID!`);
    } else {
      enqueueSnackbar(`Stopped ${type} ID.`);
    }
  }

  return (
    <>
      {asCard ? (
        <Card sx={{ overflow: 'visible' }}>
          <CardHeader
            action={
              <>
                <IconButton aria-label="favorite" onClick={handleFavoriteClick}>
                  {isFav ? <Star /> : <StarBorder />}
                </IconButton>
                <IconButton aria-label="favorite" onClick={handleLikeClick}>
                  {showLikes ? (
                    <Badge
                      color="primary"
                      badgeContent={user_fav?.user_likes}
                      max={999}
                    >
                      <Visibility />
                    </Badge>
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </>
            }
            title={dateFormatted}
            titleTypographyProps={{ fontSize: '1rem' }}
            sx={{ paddingBottom: 0 }}
          />
          <CardActionArea onClick={goToTrack}>
            <CardHeader
              avatar={<Avatar src={art_url || defaultArt} />}
              title={trackTitle}
              subheader={artist}
            />
          </CardActionArea>
        </Card>
      ) : (
        <TableRow
          sx={{
            '.MuiTableCell-root': { padding: 1 },
          }}
        >
          <TableCell component="th" scope="row">
            <Avatar
              src={art_url || defaultArt}
              sx={{ width: 56, height: 56 }}
            />
          </TableCell>
          <TableCell align="left">
            <ArtistsName
              artists={artists || []}
              underline="hover"
              linkColor="primary"
            />
          </TableCell>
          <TableCell align="left">
            <Link
              href={''}
              to={`/tracks/${id}`}
              fontWeight="bold"
              underline="hover"
            >
              {title}
            </Link>
          </TableCell>
          <TableCell align="left">
            <Link href="" to={`/sets/${set_meta?.id}`} underline="hover">
              {set_meta?.set_name}
            </Link>
          </TableCell>
          <TableCell align="right">
            <Stack direction="row" alignItems="center">
              <IconButton aria-label="favorite" onClick={handleLikeClick}>
                <Badge
                  color="primary"
                  badgeContent={user_fav?.user_likes}
                  max={999}
                >
                  {showLikes ? <Visibility /> : <VisibilityOutlined />}
                </Badge>
              </IconButton>
            </Stack>
          </TableCell>
          <TableCell align="left">{dateFormatted}</TableCell>
        </TableRow>
      )}
    </>
  );
};

TrackItem.propTypes = {
  item: PropTypes.object,
  asCard: PropTypes.bool,
};

const TracksTable = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (!items.length) return <></>;

  return (
    <>
      {isMobile ? (
        <Box sx={{ flexGrow: 1, textAlign: 'left' }} my={3}>
          <Grid container spacing={2}>
            {items.map((item) => (
              <Grid item xs={12} lg={3} key={item.id}>
                <TrackItem item={item} asCard />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ overflow: 'visible' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': { padding: '16px 8px' },
                }}
              >
                <TableCell
                  sx={{
                    width: '5%',
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '20%',
                  }}
                >
                  Artist
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '15%',
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '25%',
                  }}
                >
                  Seen in Set
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '15%',
                  }}
                >
                  Genre
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: '10%',
                  }}
                >
                  First seen date
                </TableCell>
                <TableCell sx={{ width: '5%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TrackItem item={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

TracksTable.propTypes = {
  items: PropTypes.array,
};

export default TracksTable;
