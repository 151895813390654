import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import defaultArt from '../assets/images/dj_kip_art.png';
import playingArt from '../assets/images/bars.gif';
import pauseArt from '../assets/images/bars-static.gif';
import { Play } from '../context/Play';
import { useApi } from '../context/ApiProvider';
import { getImg } from '../utils/getImg';
import {
  TableRow,
  TableCell,
  Avatar,
  Badge,
  Link,
  styled,
  IconButton,
} from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import MobileCard from './MobileCard';
import ArtistsName from './ArtistsName';
import { useSnackbar } from 'notistack';

const TrackPosition = styled(Avatar)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 24,
  left: 32,
  backgroundColor: theme.palette.primary.main,
}));

const TrackListItem = ({ track, asCard }) => {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const api = useApi();
  const {
    art_url_xl,
    title,
    label_name,
    artist,
    release_date,
    bpm,
    genre,
    user_likes,
    artists,
    label_meta_id,
    key,
    mix_type,
    is_fav,
    show_likes,
  } = track.track_meta;
  const [isFav, setIsFav] = useState(is_fav);
  const [userLikes, setUserLikes] = useState(user_likes);
  const [showLikes, setShowLikes] = useState(show_likes);
  const { track_position, track_meta_id, start_time, end_time, set_meta_id } =
    track;

  const dateFormatted = release_date
    ? new Date(release_date).toLocaleDateString('en-US')
    : '';
  const trackUrlRedirect = `/tracks/${track_meta_id}`;
  const labelUrlRedirect = `/labels/${label_meta_id}`;

  const mix_artists = artists.filter((artist) => artist.role === 'mixer');
  const mix_artistsNames = mix_artists
    .map((artist) => artist.artist_meta.name)
    .join(' & ');

  const trackTitle = `${title} ${
    mix_artistsNames ? `(${mix_artistsNames} ${mix_type})` : ''
  }`;

  const trackArt = getImg(art_url_xl) || defaultArt;
  const { isPlaying, playingProgress, playingMetaId } = useContext(Play);
  const isTrackPlaying =
    playingMetaId === set_meta_id &&
    playingProgress >= start_time &&
    playingProgress < end_time &&
    isPlaying;

  useEffect(() => {
    setIsFav(is_fav);
    setShowLikes(show_likes);
    setUserLikes(user_likes);
  }, [is_fav, show_likes, user_likes]);

  function goToTrack() {
    navigate(trackUrlRedirect);
  }

  async function handleFavoriteClick() {
    const response = await api.put(`/favs/track/${track_meta_id}`);
    if (response.ok) {
      setIsFav(response.body.is_fav);
      handleLikeNotification(response.body.is_fav, 'favorites');
    }
  }

  async function handleLikeClick() {
    const response = await api.put(`/favs/track/${track_meta_id}/likes-toggle`);
    if (response.ok) {
      setUserLikes(response.body.total_user_likes);
      setShowLikes(response.body.show_likes);
      setIsFav(response.body.is_fav);
      handleLikeNotification(response.body.show_likes, 'likes');
    }
  }

  function handleLikeNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`Track added to ${type}!`);
    } else {
      enqueueSnackbar(`Track removed from ${type}!`);
    }
  }

  const cardStyles = {
    overflow: 'visible',
    border: isTrackPlaying ? '1px solid' : 0,
    borderColor: 'primary.main',
  };

  return (
    <>
      {asCard ? (
        <MobileCard
          handleFavoriteClick={handleFavoriteClick}
          handleLikeClick={handleLikeClick}
          isFav={isFav}
          showLikes={showLikes}
          likes={user_likes}
          date={dateFormatted}
          goToItem={goToTrack}
          img={trackArt}
          title={artist}
          subtitle={trackTitle}
          subtitleExtra={label_name}
          chips={[bpm, key, genre]}
          cardStyles={cardStyles}
          trackPosition={track_position}
          isTrackPlaying={isTrackPlaying}
          playingArt={isPlaying ? playingArt : pauseArt}
          isTrackListItem={true}
        />
      ) : (
        <TableRow>
          <TableCell component="th" scope="row" sx={{ position: 'relative' }}>
            {isTrackPlaying && (
              <TrackPosition src={playingArt} sx={{ bgcolor: 'info.main' }} />
            )}
            <Badge
              color="primary"
              badgeContent={`#${track_position}`}
              max={999}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{ ml: 1 }}
            >
              <Avatar src={trackArt} sx={{ width: 56, height: 56 }} />
            </Badge>
          </TableCell>
          <TableCell align="left" sx={{ maxWidth: 200 }}>
            <ArtistsName
              artists={artists || []}
              underline="hover"
              linkColor="primary"
            />
          </TableCell>
          <TableCell align="left">
            <Link
              href={''}
              to={trackUrlRedirect}
              underline="hover"
              fontWeight="bold"
            >
              {title}
            </Link>
            {mix_artists.length > 0 && (
              <>
                {' ('}
                {mix_artists.map((mix_artist, index) => (
                  <React.Fragment key={mix_artist.id}>
                    <Link
                      href={''}
                      to={`/artists/${mix_artist.artist_meta.id}`}
                      underline="hover"
                      fontWeight="bold"
                    >
                      {mix_artist.artist_meta.name}
                    </Link>
                    {index < mix_artists.length - 1 && <spam> & </spam>}
                  </React.Fragment>
                ))}{' '}
                {mix_type}
                {')'}
              </>
            )}
          </TableCell>
          <TableCell
            align="left"
            sx={{ display: { xs: 'none', md: 'table-cell' } }}
          >
            <Link href="" to={labelUrlRedirect} underline="hover">
              {label_name}
            </Link>
          </TableCell>
          <TableCell
            align="left"
            sx={{ display: { xs: 'none', md: 'table-cell' } }}
          >
            {dateFormatted}
          </TableCell>
          <TableCell
            align="left"
            sx={{ display: { xs: 'none', md: 'table-cell' } }}
          >
            {genre}
          </TableCell>
          <TableCell
            align="left"
            sx={{ display: { xs: 'none', md: 'table-cell' } }}
          >
            {key}
          </TableCell>
          <TableCell
            align="left"
            sx={{ display: { xs: 'none', md: 'table-cell' } }}
          >
            {bpm}
          </TableCell>
          <TableCell
            align="right"
            sx={{ display: { xs: 'none', md: 'table-cell' } }}
          >
            <IconButton aria-label="favorite" onClick={handleLikeClick}>
              {showLikes ? (
                <Badge color="primary" badgeContent={userLikes} max={999}>
                  {showLikes ? <Favorite /> : <FavoriteBorder />}
                </Badge>
              ) : (
                <>{showLikes ? <Favorite /> : <FavoriteBorder />}</>
              )}
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

TrackListItem.propTypes = {
  track: PropTypes.object,
  asCard: PropTypes.bool,
};

export default TrackListItem;
